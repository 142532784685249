.taxonomy-list {
    display: flex;
    flex-flow: column nowrap;
    list-style: none;
    padding: 1em 0;

    @media (min-width: $two-column-width) {
        flex-flow: row wrap;
        justify-content: space-between;
    }
}

.taxonomy-term {
    background: $brand-primary;
    color: $white;
    padding: 2em 1em;
    margin-top: 2em;

    @media (min-width: $two-column-width) {
        flex-basis: 48%;
    }

    &-title {
        font-size: modular-scale(2);

        &-link {
            color: $white;
            text-decoration: none;
            text-transform: uppercase;
            transition: box-shadow $base-duration $base-timing;

            &:hover {
                box-shadow: 0 1px 0 0 $white;
                color: $white;
            }
        }
    }

    &-list {
        list-style: inside;

        &-item {
            font-size: modular-scale(1);

            &-date {
                &::before {
                    content: " • "
                }
            }

            &-link {
                color: $white;
                font-weight: 700;
                text-decoration: none;

                &:hover {
                    box-shadow: 0 1px 0 0 $white;
                    color: $white;
                }
            }
        }
    }
}
