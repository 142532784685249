@import '../bourbon/bourbon/library/font-stacks';

// Flexbox
$two-column-width: 60em;
$sidebar-width: 16em;

// Typography
$base-font-family: $font-stack-system;
$heading-font-family: $base-font-family;

// Font Sizes
$base-font-size: 1em;

// Line height
$base-line-height: 1.5;
$heading-line-height: 1.2;

// Other Sizes
$base-border-radius: 3px;
$base-spacing: $base-line-height * 1em;
$small-spacing: $base-spacing * 0.5;
$base-z-index: 0;

// Colors
$brand-primary: #279713;
$brand-text: #313332;
$blue: #1565c0;
$dark-gray: #313332;
$medium-gray: lighten($dark-gray, 40%);
$light-gray: lighten($dark-gray, 66.6%);
$white: #fff;

// Font Colors
$base-font-color: $dark-gray;
$action-color: $brand-primary;

// Border
$base-border-color: $light-gray;
$base-border: 1px solid $base-border-color;

// Background Colors
$base-background-color: #fff;
$secondary-background-color: tint($base-border-color, 75%);

// Forms
$form-box-shadow: inset 0 1px 3px rgba(#000, 0.06);
$form-box-shadow-focus: $form-box-shadow, 0 0 5px adjust-color($action-color, $lightness: -5%, $alpha: -0.3);

// Animations
$base-duration: 150ms;
$base-timing: ease;
$base-transition: $base-duration $base-timing;

$sans-serif-font: sans-serif;
